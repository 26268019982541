



















































































































import axios from 'axios';
import moment from 'moment-timezone';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import { BaseVue } from '@/BaseVue';
import CreateRateTableModal from '@/components/pricing/CreateRateTableModal.vue';
import UiButton from '@/components/ui/UiButton.vue';
import UiDataTable from '@/components/ui/UiDataTable.vue';
import UiLoading from '@/components/ui/UiLoading.vue';
import UiPagination from '@/components/ui/UiPagination.vue';
import UiSelect2 from '@/components/ui/UiSelect2.vue';
import { RowAction, RowActionPlace, RowActionType } from '@/models/uiDataTable';
import { CancelablePromise, CanceledError, makeCancelable } from '@/utils/CancelablePromise';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';
import numberUtils from '@/utils/numberUtils';

import { RateTable as RateTableType, RateTableEntry } from './types/rateTableTypes';

@Component({
  components: {
    CreateRateTableModal,
    UiButton,
    UiSelect2,
    UiPagination,
    UiDataTable,
    UiLoading,
  },
})
export default class RateTable extends BaseVue {
  numberFormat = numberUtils.format;
  svcURL = baConfig.api2Url || '';
  selectedRateTableId: string | null = null;
  canCreateAndImport = false;
  isCreatingTable = false;
  isLoadingRateTables = false;
  isLoadingRates = 0;
  isExportingRates = false;
  isDeletingRows = false;
  displayDeletedRows = false;

  pagination = {
    pageNumber: 1,
    pageSize: 10,
    total: 0,
  };

  sort?: {
    field: string;
    order: 'asc' | 'desc';
  } = {
    field: '',
    order: 'desc',
  };

  readonly headers = [
    {
      id: 'startDate',
      label: this.$tc('_startDate') + ' (UTC)',
      defaultVisibility: true,
      // filterable: true,
      // sortable: true,
      // rangeFilter: true,
      type: 'date',
    },
    {
      id: 'endDate',
      label: this.$tc('_endDate') + ' (UTC)',
      defaultVisibility: true,
      // filterable: true,
      // sortable: true,
      // rangeFilter: true,
      type: 'date',
    },
    {
      id: 'ticker',
      label: this.$tc('_ticker', 2),
      defaultVisibility: true,
      // filterable: true,
      // sortable: true,
    },
    {
      id: 'rate',
      label: this.$tc('_rate'),
      defaultVisibility: true,
      // filterable: true,
      // sortable: true,
    },
  ];

  selectedRowIds: string[] = [];
  selectableRowCallback = (rate: RateTableEntry) => !rate.deleted;

  readonly tableActions = [{ label: 'Show deleted rates', value: 'toggle_deleted' }];
  readonly selectedRowsActions: RowAction[] = [
    {
      label: 'Delete',
      value: this.deleteSelected,
      disabled: this.isDeletingRows,
      type: RowActionType.Button,
      place: [RowActionPlace.RowActions],
      sortIndex: 0,
    },
  ];

  rateTables: RateTableType[] = [];
  rateTableRates: RateTableEntry[] = [];
  previousPromise: CancelablePromise<any> | null = null;

  mounted() {
    this.canCreateAndImport =
      this.checkScope(this.scopeLiterals.PricingRulesCreate) && this.checkScope(this.scopeLiterals.PricingRulesUpdate);

    this.loadRateTables();
  }

  public get fiat() {
    const baseCurrency = this.$store.state.currentOrg.baseCurrency ?? 'USD';
    return (
      this.$store.getters['fiats/FIATS']?.find(
        (fiat: { name: string; symbol: string }) => fiat.name === baseCurrency
      ) ?? {
        name: baseCurrency,
        symbol: '$',
      }
    );
  }

  public get ratesOnPage() {
    return this.rateTableRates.slice(
      (this.pagination.pageNumber - 1) * this.pagination.pageSize,
      this.pagination.pageNumber * this.pagination.pageSize
    );
  }

  getFormattedDate(rawDate: string | number) {
    return moment.utc(rawDate).format('YYYY-MM-DD HH:mm:ss');
  }

  onSort(sort: { id: string; asc: boolean }) {
    this.sort = {
      field: sort.id,
      order: sort.asc ? 'asc' : 'desc',
    };

    // this.loadRateTableData();
  }

  onFilter(filter: any) {
    // this.loadRateTableDate();
  }

  onPageChange(page: any) {
    this.pagination.pageNumber = page;
    // this.loadRateTableDate();
  }

  onItemsPerPageChange(itemsPerPage: number) {
    this.pagination.pageSize = itemsPerPage;
    // this.loadRateTableData();
  }

  onRateTableSelect(rateTableId: any) {
    if (rateTableId) {
      this.rateTableRates = [];
      this.loadRateTableData();
    }
  }

  onRowSelectionChange(selections: RateTableEntry[]) {
    this.selectedRowIds = selections.map((r) => r.id);
  }

  public handleActionOnTable(handler: string | unknown) {
    if (typeof handler !== 'string') {
      (handler as () => void)();
    } else if (handler === 'toggle_deleted') {
      this.toggleDisplayDeletedRows();
    }
  }

  async afterCreate(data: { id?: string; failed?: boolean }) {
    this.isCreatingTable = false;
    if (data.id) {
      this.selectedRateTableId = data.id;
      this.rateTableRates = [];
      this.loadRateTableData();
    }
    if (!data.failed) await this.loadRateTables();
  }

  async loadRateTables() {
    this.isLoadingRateTables = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(this.svcURL, ['v2', 'orgs', orgId, 'rate-tables']);
      const resp = await axios.get<{ rateTables: RateTableType[] }>(endpointURL, {
        withCredentials: true,
      });

      this.isLoadingRateTables = false;

      if (resp.status === 200) {
        this.rateTables = resp.data?.rateTables ?? [];
      } else {
        this.showErrorSnackbar('Could not fetch rate tables');
      }
    } catch (error) {
      console.error(error);
      this.isLoadingRateTables = false;
      this.showErrorSnackbar('Unexpected error while loading rate tables');
    }
  }

  async loadRateTableData() {
    if (!this.selectedRateTableId) return;

    this.isLoadingRates++;

    const orgId = this.$store.state.currentOrg.id;
    const endpointURL = getEndpointUrl(this.svcURL, ['v2', 'orgs', orgId, 'rate-tables', this.selectedRateTableId]);

    try {
      this.previousPromise?.cancel();

      const originalPromise = axios.get<{ rates: RateTableEntry[] }>(endpointURL, {
        withCredentials: true,
      });
      const cancelablePromise = makeCancelable(originalPromise);
      this.previousPromise = cancelablePromise;
      const resp = await cancelablePromise;

      if (resp.status === 200) {
        this.rateTableRates = resp.data?.rates ?? [];

        if (!this.displayDeletedRows) {
          this.rateTableRates = this.rateTableRates.filter((rate) => !rate.deleted);
        }

        this.pagination.total = this.rateTableRates.length;
      } else {
        this.showErrorSnackbar('Could not fetch rates!');
      }

      this.isLoadingRates--;
    } catch (error) {
      this.isLoadingRates--;
      if (error instanceof CanceledError) {
        console.info('The get rate table data (rates) promise was cancled');
      } else {
        console.error(error);
        this.showErrorSnackbar('Unexpected error while loading rates');
      }
    }
  }

  async exportRateTable() {
    if (this.isExportingRates) return;

    this.isExportingRates = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(this.svcURL, ['v2', 'orgs', orgId, 'rate-tables', this.selectedRateTableId]);

      const resp = await axios.get(endpointURL, {
        withCredentials: true,
        headers: {
          Accept: 'text/csv; charset=utf-8',
        },
      });

      this.isExportingRates = false;

      if (resp.status === 200 && !!resp.data) {
        let filename = resp.headers['content-disposition']
          ?.split(';')
          .find((n) => n.includes('filename='))
          ?.replace('filename=', '')
          .trim();

        if (!filename) {
          const table =
            this.rateTables.find((t) => t.id === this.selectedRateTableId)?.name ?? this.selectedRateTableId;
          const orgId = this.$store.state.currentOrg.id;
          filename = `${table}_orgId_${orgId}_rates.csv`;
        }

        const contentType = resp.headers['content-type'];

        this.downloadRatesCSV(resp.data, contentType, filename);
      } else {
        this.showErrorSnackbar('Could not get rate tables file');
      }
    } catch (e) {
      console.error(e);
      this.showErrorSnackbar('Unexpected error while exporting rates');
    }
  }

  private toggleDisplayDeletedRows() {
    this.displayDeletedRows = !this.displayDeletedRows;
    this.loadRateTableData();

    // The timeout keeps the action label unchanged long enough for the actions dropdown to close
    setTimeout(() => {
      const toggleAction = this.tableActions.find((action) => action.value === 'toggle_deleted');
      if (toggleAction) toggleAction.label = this.displayDeletedRows ? 'Hide deleted rate' : 'Show deleted rates';
    }, 100);
  }

  private async deleteSelected() {
    if (this.isDeletingRows || !this.selectedRowIds?.length) return;

    this.isDeletingRows = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(this.svcURL, [
        'v2',
        'orgs',
        orgId,
        'rate-tables',
        this.selectedRateTableId,
        'rows',
      ]);
      const resp = await axios.delete(endpointURL, {
        withCredentials: true,
        data: {
          rowIds: this.selectedRowIds,
        },
      });
      this.isDeletingRows = false;

      if (resp.status === 200) {
        this.showSuccessSnackbar(`Successfully deleted ${this.selectedRowIds.length} rates`);
        this.loadRateTableData();
      } else {
        this.showErrorSnackbar('Could not delete rates');
      }
    } catch (error) {
      console.error(error);
      this.isDeletingRows = false;
      this.showErrorSnackbar('Unexpected error while deleting rates');
    }
  }

  private downloadRatesCSV(data: string, type: string, filename: string) {
    const blob = new Blob([data], { type });
    const link = document.createElement('a');
    const url = window.URL.createObjectURL(blob);
    link.href = url;
    link.download = filename;
    link.click();
    window.URL.revokeObjectURL(url);
  }

  @Watch('$store.state.currentOrg.id')
  onOrgChange() {
    if (this.rateTables) {
      this.selectedRateTableId = null;
      this.rateTables = [];
      this.rateTableRates = [];
      this.loadRateTables();
    }
  }
}
