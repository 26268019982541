
















import axios from 'axios';
import Component from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

import { baConfig } from '@/../config';
import { BaseVue } from '@/BaseVue';
import { getEndpointUrl } from '@/utils/endpointUrlUtil';

import UiButton from '../ui/UiButton.vue';
import UiModal from '../ui/UiModal.vue';
import UiTextEdit from '../ui/UiTextEdit.vue';

@Component({
  components: { UiButton, UiModal, UiTextEdit },
})
export default class CreateRateTableModal extends BaseVue {
  @Prop({ default: false })
  public open!: boolean;

  svcURL = baConfig.api2Url || '';
  tableName = null;
  isLoading = false;

  public async createRateTable() {
    if (!this.tableName) return;
    this.isLoading = true;

    try {
      const orgId = this.$store.state.currentOrg.id;
      const endpointURL = getEndpointUrl(this.svcURL, ['v2', 'orgs', orgId, 'rate-tables']);
      const resp = await axios.post<{ createdId: string }>(
        endpointURL,
        { name: this.tableName },
        { withCredentials: true }
      );

      this.isLoading = false;

      if (resp.status === 200) {
        this.showSuccessSnackbar('Table created successfully!');
        this.closeModal(false, resp.data?.createdId);
      } else {
        this.showErrorSnackbar('Table could not be created!');
        this.closeModal(true);
      }
    } catch (error) {
      console.error(error);
      this.isLoading = false;
      this.showErrorSnackbar('Unexpected error when trying to create the rate table');
      this.closeModal(true);
    }
  }

  public closeModal(failed?: boolean, id?: string) {
    if (this.isLoading) return;

    this.tableName = null;
    this.$emit('close', { failed, id });
  }
}
